@use '@/styles/utils/mixins.scss' as *;

.formHeaderWraper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3.75rem;
  max-width: 52.5rem;
  @include tab() {
    margin-bottom: 2.5rem;
  }

  .formHeaderDescription {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0.625rem;
      font-weight: 500;
      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
    h1 {
      font-size: 6rem;
    }
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h5 {
      font-size: 2.125rem;
    }
    h6 {
      font-size: 1.5rem;
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
